import {
  query,
  updateDoc,
  where,
  collection,
  getDocs,
  addDoc,
  orderBy,
  Timestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { v4 as uuidv4 } from "uuid";

export const autoService = {
  Addfields,
  getAutoList,
  updateFields,
  deleteAutoOption,
  getroomById,
};
const FIELDTYPE = [
  "room_name",
  "room_generale_description",
  "image_description",
  "room_deffect_notice",
  "counter_location",
  "key_name",
  "key_notice",
  "product_installation_position",
  "counter_name",
  "controle_comment",
  "title"
];
async function getAutoList(
  type,
  level,
  id,
  roomID = null,
  deffectNameID = null
) {
  let queryRef;
  if (level === 0) {
    // Construct the base query without the OR condition
    queryRef = query(
      collection(db, "xc_auto_complete_feature"),
      where("field_type", "==", FIELDTYPE.indexOf(type)),
      where("level", "==", 0),
      where("marked_for_deletion", "==", false)
    );

    // Fetch the documents matching the base query
    const querySnapshot = await getDocs(queryRef);

    // Convert the snapshot to an array
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);

    // Filter the array based on company_id condition
    const filteredArray = formattedArray.filter(
      (doc) => doc.company_id === id || doc.company_id === ""
    );

    return filteredArray;
  } else if (level == 1) {
    // Construct the base query without the OR condition
    queryRef = query(
      collection(db, "xc_auto_complete_feature"),
      where("field_type", "==", FIELDTYPE.indexOf(type)),
      where("marked_for_deletion", "==", false),
      where("level", "==", 1),
      where("sub_parent_ids", "array-contains", roomID)
    );

    // Fetch the documents matching the base query
    const querySnapshot = await getDocs(queryRef);

    // Convert the snapshot to an array
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
    // Filter the array based on company_id condition
    const filteredArray = formattedArray.filter(
      (doc) => doc.company_id === id || doc.company_id === ""
    );

    return filteredArray;
  } else {
    // Construct the base query without the OR condition
    queryRef = query(
      collection(db, "xc_auto_complete_feature"),
      where("field_type", "==", FIELDTYPE.indexOf(type)),
      where("marked_for_deletion", "==", false),
      where("level", "==", 2),
      where("parent_ids", "array-contains", roomID)
    );

    // Fetch the documents matching the base query
    const querySnapshot = await getDocs(queryRef);

    // Convert the snapshot to an array
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
    // Filter the array based on company_id condition
    const filteredArray = formattedArray.filter(
      (doc) => doc.company_id === id || doc.company_id === ""
    );
    const finalArray = filteredArray.filter(
      (doc) => doc.sub_parent_ids[doc.sub_parent_ids.indexOf(deffectNameID)]
    );
    return finalArray;
  }
}

async function deleteAutoOption(
  option,
  company_id,
  field_type,
  level,
  subId = null
) {
  const xcAutocompleteRef = collection(db, "xc_auto_complete_feature");
  const queryRef = query(
    xcAutocompleteRef,
    where("company_id", "==", company_id),
    where("field_type", "==", FIELDTYPE.indexOf(field_type)),
    where("marked_for_deletion", "==", false),
    where("is_static", "==", false),
    where("level", "==", level),
    where("value", "==", option)
  );
  const querySnapshot = await getDocs(queryRef);
  if (!querySnapshot.empty) {
    // If the value exists, update the document
    if (level == 0) {
      const docRef = querySnapshot.docs[0].ref;
      await updateDoc(docRef, {
        marked_for_deletion: true,
      });
      if (field_type == "room_name") {
        await deleteFirstlevel(
          querySnapshot.docs[0].data().id,
          field_type,
          company_id
        );
      }
    } else {
      const newArray = querySnapshot.docs[0]
        .data()
        .sub_parent_ids.filter((element) => {
          return (
            querySnapshot.docs[0].data().sub_parent_ids[element] !=
            querySnapshot.docs[0].data().sub_parent_ids[subId]
          );
        });
      if (newArray.length < 1) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, {
          marked_for_deletion: true,
        });
      } else {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, { sub_parent_ids: newArray });
      }
    }
  }
}
async function Addfields(obj, company_id) {
  const xcAutocompleteRef = collection(db, "xc_auto_complete_feature");
  // Check if the value already exists for the company_id and field_type
  const queryRef = query(
    xcAutocompleteRef,
    where("company_id", "==", company_id),
    where("field_type", "==", obj.field_type),
    where("level", "==", obj.level),
    where("value", "==", obj.value)
  );
  const querySnapshot = await getDocs(queryRef);
  if (!querySnapshot.empty) {
    // If the value exists, update the document
    if (querySnapshot.docs[0].data().is_static == false) {
      if (obj.level == 0) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, {
          marked_for_deletion: false,
          created_at: obj.created_at,
          updated_at: obj.updated_at,
        });
      } else if (obj.level == 1) {
        let newArray = querySnapshot.docs[0].data().sub_parent_ids;
        if (checkAndAddValue(newArray, obj.sub_parent_ids[0])) {
          const docRef = querySnapshot.docs[0].ref;
          await updateDoc(docRef, {
            marked_for_deletion: false,
            created_at: obj.created_at,
            updated_at: obj.updated_at,
            sub_parent_ids: newArray,
          });
        } else {
          const docRef = querySnapshot.docs[0].ref;
          await updateDoc(docRef, {
            marked_for_deletion: false,
            created_at: obj.created_at,
            updated_at: obj.updated_at,
          });
        }
      } else if (obj.level == 2) {
        let newArray = querySnapshot.docs[0].data().sub_parent_ids;
        let parentArray = querySnapshot.docs[0].data().parent_ids;
        if (!checkAndAddValue(newArray, obj.sub_parent_ids[0])) {
          if (!checkAndAddValue(parentArray, obj.parent_ids[0])) {
            const docRef = querySnapshot.docs[0].ref;
            await updateDoc(docRef, {
              marked_for_deletion: false,
              created_at: obj.created_at,
              updated_at: obj.updated_at,
              sub_parent_ids: newArray,
              parent_ids: parentArray,
            });
          } else {
            const docRef = querySnapshot.docs[0].ref;
            await updateDoc(docRef, {
              marked_for_deletion: false,
              created_at: obj.created_at,
              updated_at: obj.updated_at,
              sub_parent_ids: newArray,
            });
          }
        } else {
          const docRef = querySnapshot.docs[0].ref;
          await updateDoc(docRef, {
            marked_for_deletion: false,
            created_at: obj.created_at,
            updated_at: obj.updated_at,
          });
        }
      }
    }
  } else {
    // Check if the count of objects with the same company_id and field_type is 30 or more
    const countQuery = query(
      xcAutocompleteRef,
      where("company_id", "==", company_id),
      where("field_type", "==", obj.field_type),
      where("level", "==", obj.level),
      where("marked_for_deletion", "==", false),
      where("is_static", "==", false),
      orderBy("updated_at", "asc")
    );
    const countQuerySnapshot = await getDocs(countQuery);

    if (countQuerySnapshot.size >= 30) {
      // If the count is 30 or more, update the oldest object
      const oldestDoc = countQuerySnapshot.docs[0];
      await updateDoc(oldestDoc.ref, obj);
    } else {
      // If the count is less than 30, add a new document
      const newDocRef = doc(db, "xc_auto_complete_feature", obj.id);
      await setDoc(newDocRef, obj);
    }
  }
}

async function updateFields(obj, company_id, oldValue) {
  const xcAutocompleteRef = collection(db, "xc_auto_complete_feature");
  // Check if the value already exists for the company_id and field_type
  const queryRef = query(
    xcAutocompleteRef,
    where("company_id", "==", company_id),
    where("field_type", "==", obj.field_type),
    where("value", "==", oldValue)
  );

  const querySnapshot = await getDocs(queryRef);
  if (!querySnapshot.empty) {
    // If the value exists, update the document
    if (querySnapshot.docs[0].data().is_static == false) {
      if (querySnapshot.docs[0].data().level == 0) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, obj);
      } else {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, {
          marked_for_deletion: true,
        });
        await Addfields(obj, company_id);
      }
    }
  }
}

async function getroomById(value, company_id, level) {
  const xcAutocompleteRef = collection(db, "xc_auto_complete_feature");
  const queryRef = query(
    xcAutocompleteRef,
    where("field_type", "==", 0),
    where("marked_for_deletion", "==", false),
    where("level", "==", level),
    where("value", "==", value)
  );
  const querySnapshot = await getDocs(queryRef);
  if (!querySnapshot.empty) {
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
    // Filter the array based on company_id condition
    const filteredArray = formattedArray.filter(
      (doc) => doc.company_id === company_id || doc.company_id === ""
    );
    return filteredArray;
  } else {
    return formatQuerySnapshotToArray(querySnapshot);
  }
}

const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};

async function deleteFirstlevel(id, field_type, company_id) {
  const xcAutocompleteRef = collection(db, "xc_auto_complete_feature");
  const queryRef = query(
    xcAutocompleteRef,
    where("company_id", "==", company_id),
    where("field_type", "==", FIELDTYPE.indexOf(field_type)),
    where("marked_for_deletion", "==", false),
    where("level", "==", 1),
    where("sub_parent_ids", "array-contains", id)
  );
  const querySnapshot = await getDocs(queryRef);
  if (!querySnapshot.empty) {
    // If the value exists, update the document
    querySnapshot.docs.forEach(async (doc) => {
      const newArray = doc.data().sub_parent_ids.filter((element) => {
        return (
          //doc.data().sub_parent_ids[element] != doc.data().sub_parent_ids[id]
          element != id
        );
      });
      if (newArray.length < 1) {
        const docRef = doc.ref;
        await updateDoc(docRef, {
          marked_for_deletion: true,
        });
        await deleteSecondlevel(doc.data().id, field_type, company_id);
      } else {
        const docRef = doc.ref;
        await updateDoc(docRef, { sub_parent_ids: newArray });
      }
    });
  }
}

async function deleteSecondlevel(id, field_type, company_id) {
  const xcAutocompleteRef = collection(db, "xc_auto_complete_feature");
  const queryRef = query(
    xcAutocompleteRef,
    where("company_id", "==", company_id),
    where("field_type", "==", FIELDTYPE.indexOf(field_type)),
    where("marked_for_deletion", "==", false),
    where("level", "==", 2),
    where("sub_parent_ids", "array-contains", id)
  );
  const querySnapshot = await getDocs(queryRef);
  if (!querySnapshot.empty) {
    // If the value exists, update the document
    querySnapshot.docs.forEach(async (doc) => {
      const newArray = doc.data().sub_parent_ids.filter((element) => {
        return element != id;
      });
      if (newArray.length < 1) {
        const docRef = doc.ref;
        await updateDoc(docRef, {
          marked_for_deletion: true,
        });
      } else {
        const docRef = doc.ref;
        await updateDoc(docRef, { sub_parent_ids: newArray });
      }
    });
  }
}

function checkAndAddValue(array, value) {
  // Iterate through the array
  for (let i = 0; i < array.length; i++) {
    // If the value is found, return true
    if (array[i] === value) {
      return true;
    }
  }

  // If the value is not found, add it to the array
  array.push(value);

  // Return true to indicate the value was added
  return false;
}
