import {
  getDocs,
  collection,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

import { db } from "../config/firebase";
export const deffectService = {
  getDeffectList,
  getProtocolById,
  Deffect_Corrected,
  downloadPdfMangel
};
const roomsTypes = [
  { value: "livingRoom", label: "Wohnzimmer" },
  { value: "bedroom", label: "Schlafzimmer" },
  { value: "kitchen", label: "Küche" },
  { value: "bathroom", label: "Badezimmer" },
  { value: "basement", label: "Keller" },
  { value: "corridor", label: "Flur" },
  { value: "secondbedroom", label: "Zweites Schlafzimmer" },
  { value: "secondbathroom", label: "Zweites Badezimmer" },
  { value: "attic", label: "Dachboden" },
  { value: "garage", label: "Garage" },
  { value: "balcony", label: "Balkon" },
  { value: "shed", label: "Schuppen" },
  { value: "garden", label: "Garten" },
  { value: "nursery", label: "Kinderzimmer" },
  { value: "other", label: "Andere" },
];
async function downloadPdfMangel(deffect_id) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/defect/export/${deffect_id}`,
    requestOptions
  ).then(handleResponse);
}
async function getProtocolById(id) { 
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("id", "==", id)
  );
  const querySnapshot = await getDocs(queryRef);
  const protocol = await formatQuerySnapshotToArray(querySnapshot);
  return protocol[0];
}
async function Deffect_Corrected(id) {
  let result ;
  const docRef = doc(db, "xc_defect", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // Update the document
    await updateDoc(docRef, {
      status: "repaired",
      controle_corrected:true,
    });
    result=true;
  } else {
    result=false;
  }
  return result 
}
async function getDeffectList(value, sortBy, filterOptions,filterValue) {
  const params = new URLSearchParams();

  const requestOptions = {
    method: "GET",
  };
  // Append each filter as individual query parameters
  params.append("company_id", value);
  params.append("filter", filterValue);
  if (sortBy) {
    params.append("sort_by", sortBy);
  }
  if (filterOptions && filterOptions.deffect_name) {
    filterOptions.deffect_name.forEach((name) =>
      params.append("defect_name", name)
    );
  }
  if (filterOptions && filterOptions.deffect_type) {
    filterOptions.deffect_type.forEach((name) =>
      params.append("defect_type", name)
    );
  }
  if (filterOptions && filterOptions.status) {
    filterOptions.status.forEach((name) => params.append("status", formatStatus(name)));
  }
  if (filterOptions && filterOptions.room_deffect_who_fix) {
    filterOptions.room_deffect_who_fix.forEach((name) =>
      params.append("room_deffect_who_fix", name)
    );
  }
  // Use the constructed URL with query parameters
  return fetch(
    `${`${process.env.REACT_APP_BASE_URL}/defect/all`}?${params.toString()}`,
    requestOptions
  ).then(handleResponse);
}

function formatStatus(status) {
  switch (status) {
    case "behoben":
      return "repaired";
    case "offen / Frist überschritten":
      return "expired";
    case "noch in der Frist":
      return "pending";
  }
}
const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};
// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.msg) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
